export default [
  {
    title: "Dashboard",
    route: "dashboard",
    icon: "HomeIcon",
    action: "read",
    resource: "Listo"
  },
  {
    title: "Patient Dashboard",
    route: "compassion-dashboard",
    icon: "HeartIcon",
    action: "read",
    resource: "Compassion"
  }
  /*{
    title: 'Dashboards',
    icon: 'HomeIcon',
    tag: '2',
    tagVariant: 'light-warning',
    children: [
      {
        title: 'eCommerce',
        route: 'dashboard-ecommerce',
      },
      {
        title: 'Analytics',
        route: 'dashboard-analytics',
      },
    ],
  },*/
];
