export default [
  {
    header: "Media",
    action: "read",
    resource: "Listo"
  },
  {
    title: "Moderate",
    route: "moderate-images",
    icon: "ImageIcon",
    action: "read",
    resource: "Listo"
  }
];
