export default [
  {
    header: "Support"
  },
  {
    title: "Queue",
    route: "support",
    icon: "HeadphonesIcon",
    action: "read",
    resource: "Listo"
  }
];
