export default [
  {
    header: "Admin",
    action: "read",
    resource: "Listo"
  },
  {
    title: "Application",
    route: "application",
    icon: "SmartphoneIcon",
    action: "read",
    resource: "Listo",
    children: [
      {
        title: "Welcome Message",
        route: "appWelcomeMessage",
        action: "read",
        resource: "Listo"
      },
      {
        title: "Blog/News",
        route: "blogNewsViews",
        action: "read",
        resource: "Listo"
      },
      {
        title: "Terms & Conditions",
        route: "termsAndConditions",
        action: "read",
        resource: "Listo"
      }
    ]
  },
  {
    title: "Users",
    route: "user",
    icon: "UserIcon",
    action: "read",
    resource: "Listo"
  },
  {
    title: "Patients",
    route: "compassionCustomers",
    icon: "UserIcon",
    action: "read",
    resource: "Compassion"
  },
  {
    title: "Onboarding",
    route: "compassionOnboarding",
    icon: "SunriseIcon",
    action: "read",
    resource: "COperator"
  },
  {
    title: "Events",
    route: "compassionEvents",
    icon: "GiftIcon",
    action: "read",
    resource: "COperator"
  },
  {
    title: "Check Expiration",
    route: "compassionExpirationCheck",
    icon: "CalendarIcon",
    action: "read",
    resource: "COperator"
  },
  {
    title: "Partners",
    route: "compassionPartners",
    icon: "ThumbsUpIcon",
    action: "read",
    resource: "Compassion"
  },
  {
    title: "Organizations",
    route: "organization",
    icon: "FramerIcon",
    action: "read",
    resource: "Listo"
  },
  {
    title: "Facilities",
    route: "facilities",
    icon: "GridIcon",
    action: "read",
    resource: "Listo"
  },
  {
    title: "Listings",
    route: "listings",
    icon: "ListIcon",
    action: "read",
    resource: "Listo"
  },
  {
    title: "Samples",
    route: "samples",
    icon: "PackageIcon",
    action: "read",
    resource: "Listo"
  },
  {
    title: "Inventory",
    route: "inventory",
    icon: "ArchiveIcon",
    action: "read",
    resource: "Listo"
  },
  {
    title: "Metrc",
    route: "metrc",
    icon: "CloudDrizzleIcon",
    action: "read",
    resource: "Listo"
    /*children: [
      {
        title: "Update Status",
        route: "metrcLastUpdate"
      }
    ]*/
  },
  {
    header: "Hall Of Flowers",
    action: "read",
    resource: "Listo"
  },
  {
    title: "HOF Contacts",
    route: "contactQueue",
    icon: "TrendingUpIcon",
    action: "read",
    resource: "Listo"
  }
];
