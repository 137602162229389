<template>
      <div class="d-sm-flex d-none user-nav">
        <h2 class="font-weight-bolder mb-0">
          {{ appTitle }}
        </h2>
      </div>
    </template>

</template>

<script>
import { BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar } from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";
import { mapState } from "vuex";
export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar
  },
  data() {
    return {
      avatarText
    };
  },
  computed: {
    ...mapState({
      appUser: (state) => state.auth.authUser
    }),

    appTitle(){
      const hn = window.location.hostname;
      if (hn && hn.toLowerCase().includes("sweetleaf")) {
        return "Listo Compassion Admin"
      }
      if(this.appUser && this.appUser.admin){
        return "Listo Admin"
      }
      if(this.appUser && this.appUser.compassionOperator){
        return  "The Sweetleaf Veteran and Patient Network."
      }
      if(this.appUser && this.appUser.compassionAdmin){
        return "Listo Compassion Admin"
      }
      return "Listo Admin"
    }
  },
  methods: {
    logout() {
      // Remove userData from localStorage
      // ? You just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    }
  }
};
</script>
