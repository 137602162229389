<template>
  <div class="navbar-container d-flex content align-items-center">
    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link" @click="toggleVerticalMenuActive">
          <feather-icon icon="MenuIcon" size="21" />
        </b-link>
      </li>
    </ul>
    <b-navbar-nav>
      <title-bar />
    </b-navbar-nav>
    <b-navbar-nav class="nav align-items-center ml-auto">
      <!-- <search-bar /> -->
      <!-- <notification-dropdown /> -->

      <user-dropdown />
    </b-navbar-nav>
  </div>
</template>

<script>
import { BLink, BNavbarNav } from "bootstrap-vue";
import UserDropdown from "./components/UserDropdown.vue";
import TitleBar from "./components/TitleBar.vue";
export default {
  components: {
    BLink,
    // Navbar Components
    BNavbarNav,
    //NotificationDropdown,
    UserDropdown,
    TitleBar
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {}
    }
  }
};
</script>
